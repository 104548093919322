import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import "@styles/base/plugins/extensions/ext-component-toastr.scss";
import { PDFDownloadLink } from "@react-pdf/renderer";
import UCCTicketingReports from "./UCCTicketingReports";
import PHOCTicketingReports from "./PHOCTicketingReports";
import moment from "moment";
import {
  get_form_main_teams,
  get_form_sub_teams,
  region,
  phoc_ticketing_report,
  ucc_ticketing_report,
} from "@src/views/Helper/endPoints";
import { SC } from "@src/views/Helper/ServerCall";
import CustomButton from "@src/views/Helper/component/CustomButton";
import "./assets/style.scss";
import CustomSelect from "@src/views/Helper/CustomSelect";
import { isEmpty } from "lodash";

function WeeklyReportGenerateModal(props) {
  const startOfPreviousWeek = moment()
    .subtract(1, "weeks")
    .startOf("week")
    .toDate();
  const endOfPreviousWeek = moment()
    .subtract(1, "weeks")
    .endOf("week")
    .toDate();

  const startOfWeek = moment().startOf("week").toDate();

  const initialState = {
    team_id: null,
    sub_team_ids: [],
    region_ids: [],
    dateRange: [startOfPreviousWeek, endOfPreviousWeek],
  };
  const columnsByStatus = [
    { title: "Region/Cluster", key: "region" },
    { title: "Total Tickets", key: "total" },
    { title: "Opened", key: "opened" },
    { title: "Reopened", key: "reopened" },
    { title: "Solved", key: "solved" },
    { title: "Closed", key: "closed" },
    { title: "Overdue Till Today", key: "overdue" },
    { title: "Overdue Last Week", key: "overdueLastWeek" },
    { title: "Closure Rate", key: "closure" },
  ];
  const columnsByTeams = [
    { title: "Region/Cluster", key: "region" },
    { title: "Mawid", key: "Mawid" },
    { title: "Mobile Clinics", key: "Mobile Clinics" },
    { title: "Musta'd", key: "Musta'd" },
    { title: "PATIENT EXPERIENCE", key: "PATIENT EXPERIENCE" },
    { title: "Raqeem", key: "Raqeem" },
    { title: "TBC", key: "TBC" },
    { title: "Total Open Tickets", key: "total" },
  ];

  const [state, setState] = useState(initialState);
  const [reportDateRange, setReportDateRange] = useState({
    forFileName: "",
    internalRange: "",
  });
  const [reports, setReports] = useState({ ucc: [], phoc: [] });
  const [reportsLoading, setReportsLoading] = useState(false);

  const [teams, setTeams] = useState([]);
  const [subTeams, setSubTeams] = useState([]);
  const [regions, setRegions] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [subTeamsLoading, setSubTeamsLoading] = useState(false);
  const [regionsLoading, setRegionsLoading] = useState(false);
  const [isInvalidTeam, setTeamValidation] = useState(false);

  useEffect(() => {
    getAlTeams();
    getRegions();
  }, []);

  useEffect(() => {
    if (state.team_id) {
      getSubTeams(state.team_id);
      setTeamValidation(false);
    } else {
      setSubTeams([]);
    }
    updateState("sub_team_ids", []);
  }, [state.team_id]);

  const getAlTeams = async () => {
    setTeamsLoading(true);
    try {
      const response = await SC.getCallWithOrgId(get_form_main_teams);
      setTeams(response.data.teams);
    } catch (error) {
      console.log(error?.response);
    } finally {
      setTeamsLoading(false);
    }
  };
  const getSubTeams = async (teamId) => {
    setSubTeamsLoading(true);
    const payload = {
      teamId: teamId?._id ? [teamId._id] : [],
      organization_id: localStorage.getItem("orgId"),
    };
    try {
      const response = await SC.postCall(get_form_sub_teams, payload);
      setSubTeams(response.data.teams);
    } catch (error) {
      console.log(error?.response);
    } finally {
      setSubTeamsLoading(false);
    }
  };

  const getRegions = async () => {
    setRegionsLoading(true);
    try {
      const response = await SC.getCall(region);
      // console.log(response.data?.regions);
      setRegions({
        ...response.data.regions,
        options: response.data.regions?.values?.map((item) => {
          return {
            _id: item,
            name: item,
          };
        }),
      });
    } catch (error) {
      console.log(error?.response);
    } finally {
      setRegionsLoading(false);
    }
  };

  const getReports = async () => {
    const { from, to, formatted } = getFormattedRange(
      state.dateRange[0],
      state.dateRange[1]
    );
    const { formatted: internalRange } = getFormattedRange(
      state.dateRange[0],
      state.dateRange[1],
      "ll"
    );
    const requestPayload = {
      from,
      to,
      teamIds: state.team_id ? [state.team_id._id] : [],
      subTeamIds: state.sub_team_ids.map((item) => item._id),
      regionIds: state.region_ids.map((item) => item._id),
    };
    if (requestPayload.teamIds.length === 0) {
      setTeamValidation(true);
    } else {
      setTeamValidation(false);
      setReportsLoading(true);

      try {
        // const [uccResponse, phocResponse] = await Promise.all([
        const [phocResponse] = await Promise.all([
          // SC.postCall_golang(ucc_ticketing_report, requestPayload),
          SC.postCall_golang(phoc_ticketing_report, requestPayload),
        ]);

        setReports({
          // ucc: formatReport(uccResponse.data?.response),
          ucc: [],
          phoc: formatReport(phocResponse.data?.response, true),
        });
        setReportDateRange({ forFileName: formatted, internalRange });
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setReportsLoading(false);
      }
    }
  };
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      const startDate = selectedDates[0];
      const startOfWeek = new Date(
        startDate.setDate(startDate.getDate() - startDate.getDay())
      );
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      setState((oldState) => {
        return { ...oldState, dateRange: [startOfWeek, endOfWeek] };
      });
    }
  };
  const updateState = (key, value) => {
    setState((oldState) => {
      return { ...oldState, [key]: value };
    });
  };
  const formatReport = (reportData, sort) => {
    return Object.entries(reportData || {})
      .sort(([, valueA], [, valueB]) => valueA?.sort - valueB?.sort)
      .map(([key, value]) => {
        const valueData = Object.entries(value?.data || {});
        let data = [];
        let columns = [];

        if (valueData.length > 0) {
          const isOpenTicketsPage = key === "Total Open Tickets Report";
          columns = isOpenTicketsPage ? columnsByTeams : columnsByStatus;
          data = [
            ...valueData.map(([dataKey, dataValue]) => ({
              ...dataValue,
              region: dataKey,
            })),
            {
              region: "total",
              ...value.teamTotalCount,
            },
          ];
        }
        return {
          title: key,
          columns,
          data,
        };
      });
  };
  const getFormattedRange = (from, to, format = "YYYY-MM-DD") => {
    from = moment(from).format(format);
    to = moment(to).format(format);
    return {
      formatted: `${from} - ${to}`,
      from,
      to,
    };
  };

  return (
    <div className="ticketing-reports">
      <Flatpickr
        value={state.dateRange}
        className=""
        placeholder="Select..."
        style={{ border: "none", display: "none" }}
        options={{
          inline: true,
          mode: "range",
          dateFormat: "Y-m-d",
          background: "lightblue",
          disable: [
            {
              from: new Date(startOfWeek.getTime()),
              to: "9999-99-99",
            },
          ],
          onChange: handleDateChange,
        }}
      />
      <CustomSelect
        label="Team"
        options={teams}
        value={state.team_id}
        name={"team_id"}
        isMulti={false}
        isLoading={teamsLoading}
        customClass={"customSelect_1 w-100 mt-2"}
        handleChange={updateState}
        selectClassPrefix="ticketing-reports--select"
        isRequired={true}
        invalid={isInvalidTeam}
      />
      <CustomSelect
        label="Sub Team"
        name="sub_team_ids"
        value={state.sub_team_ids}
        isDisabled={!state.team_id?._id}
        isLoading={subTeamsLoading}
        options={subTeams}
        handleChange={updateState}
        customClass={"customSelect_1 w-100"}
        selectClassPrefix="ticketing-reports--select"
      />
      <CustomSelect
        label="Region/Cluster"
        name="region_ids"
        value={state.region_ids}
        isLoading={regionsLoading}
        options={regions.options}
        handleChange={updateState}
        customClass={"customSelect_1 w-100"}
        selectClassPrefix="ticketing-reports--select"
      />
      <div className="reports-modal-footer">
        {reportsLoading ? (
          <p className="reports-loading-text text-primary">
            Loading Reports...
          </p>
        ) : (
          (reports.phoc.length > 0 || reports.ucc.length > 0) && (
            <ul className="reports-links-container">
              <li>
                <PDFDownloadLink
                  document={
                    <PHOCTicketingReports
                      reports={reports.phoc}
                      loading={reportsLoading}
                      date={reportDateRange.internalRange}
                    />
                  }
                  fileName={`PHOC Weekly Report ${reportDateRange.forFileName}.pdf`}
                >
                  {({ loading }) =>
                    loading
                      ? "Loading PHOC Weekly Report"
                      : `PHOC Weekly Report ${reportDateRange.forFileName}`
                  }
                </PDFDownloadLink>
              </li>
              {/* <li>
                <PDFDownloadLink
                  document={
                    <UCCTicketingReports
                      reports={reports.ucc}
                      date={reportDateRange.internalRange}
                      loading={reportsLoading}
                    />
                  }
                  fileName={`UCC Weekly Report ${reportDateRange.forFileName}.pdf`}
                >
                  {({ loading }) =>
                    loading
                      ? "Loading UCC Weekly Report"
                      : `UCC Weekly Report ${reportDateRange.forFileName}`
                  }
                </PDFDownloadLink>
              </li> */}
            </ul>
          )
        )}
        <CustomButton
          color="primary"
          disabled={reportsLoading}
          text={reportsLoading ? "Generating..." : "Generate Reports"}
          className="mb-2"
          onClick={getReports}
        />
      </div>
    </div>
  );
}

export default WeeklyReportGenerateModal;
