import { EditorState } from "draft-js";
import { imageOverlay } from "leaflet";
import NotificationIcon from "@src/assets/images/icons/notification.svg";

import moment from "moment";

// -- Create User Form -- //
export const userObj = {
  email: "",
  name: "",
  sector: [],
  password: "",
  teamIds: [],
  active: false,
  dynamic_fields: {},
};
// -- Create Organization Form --//
export const organizationObj = {
  name: "",
  canEscalateAllTickets: false,
  canEscalateMultipleTickets: false,
  canAutoEscalateTickets: false,
  showFields: [],
  required: [],
};
// -- Create Team Form -- //
export const teamObj = {
  organization_ids: [],
  parent_id: [],
  subTeamids: [],
  name: "",
};
// -- Create ClosedReasons Form -- //
export const closedreasonsObj = {
  name: "",
};
// -- Create Macros Form -- //
export const macrosObj = {
  name: "",
};
// -- Create Facility Form -- //
export const facilityObj = {
  name: "",
  region: "",
};
export const dynamicfieldObj = {
  name: "",
  team: [],
  type: [],
  org: [],
  dynamicFieldType: [],
  addValues: {
    count: 1,
    arrCount: [1],
    values: [{ id: 1, value: "" }],
    originalValues: [],
  },
  requiredField: false,
  users: false,
};
export const notificationObj = {
  Comments_Notfi: false,
  Ticket_Notfi: false,
  SolveTicket_Notfi: false,
  Escalation_Notfi: false,
  OpenTicket_Notfi: false,
  CloseTicket_Notfi: false,
  ReopenTicket_Notfi: false,
};
//Assign to ticket
export const assignObj = {
  team_ids: [],
  sub_team: [],
  representatives: [],
  reason: "",
};
//edit profile
export const profileObj = {
  name: "",
  phone: "",
  image: [],
  password: "",
};
//user Requester Obj
export const requesterObj = {
  name: "",
  email: "",
  password: "",
  emailNoti: false,
  organization_ids: [],
  team_ids: [],
  dynamic_fields: [],
};
// singup Requester Obj
export const singupObj = {
  name: "",
  email: "",
  password: "",
  emailNoti: false,
  team_ids: [],
  dynamic_fields: [],
};
//create escalation obj
export const escalationObj = {
  team_ids: [],
  sub_team: [],
  sub_sub_team: [],
  sub_sub_team_dynamic_field_values: [],
  dynamic_field: [],
  dynamic_field_value: [],
  number_escalation: 0,
  level: [],
};
//support form obj
export const supportObj = {
  attachment: [],
  subject: "",
  // region: "",
  organization_ids: [],
};
//create ticket branch
export const ticketObj = {
  subject: "",
  email: [],
  content: "",
  status_id: [],
  priority_id: [],
  team_ids: [],
  sub_team: [],
  tags: [],
  due_time: new Date(moment().add(2, "hours")),
  due_date: new Date(moment().add(1, "days").format("YYYY-MM-DD")),
  attachment: [],
  dynamic_fields: [],
};
//init ticket fields object
export const initFieldsObject = {
  name: null,
  requiredField: false,
  values: [""],
  _id: "",
};
//reset password object
export const resetPasswordObj = {
  email: "",
  password: "",
  conform_password: "",
};
export const PeroirtyOp = [
  { label: "medium", value: "medium" },
  { label: "low", value: "low" },
  { label: "high", value: "high" },
  { label: "critical", value: "critical" },
];
// -- Tour Steps --//
export const stepsdash = [
  {
    target: ".dropdown-notification",
    content: (
      <div>
        Click on notifications
        <img
          src={NotificationIcon}
          size={30}
          className="cursor-pointer"
          id="noti"
          alt="notifications"
        />
        icon to get to the settings
      </div>
    ),
    disableBeacon: true,
  },
];
export const stepsnoti = [
  {
    target: ".noti-settings",
    content:
      "You can turned on or off your desired notification alert in settings Tab.",
    disableBeacon: true,
    placement: "left",
  },
];
export const dashboardObj = {
  data: {},
  loading: true,
};

// -- Create Permission Form -- //
export const permissionObj = {
  name: "",
  label: "",
  is_menu: false,
  icon: "",
  module_id: null,
  order: "",
  description: "",
  permission_type: null,
  is_new_tab: false,
  parent_label: "",
  path: "",
};

// -- Create Permission Form -- //
export const assignedPermissionObj = {
  permission_ids: {},
  role_id: "",
};
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? 0 : 0, // Remove border on focus
    boxShadow: state.isFocused ? 0 : provided.boxShadow, // Remove box shadow on focus
    "&:hover": {
      border: state.isFocused ? 0 : provided.border, // Remove border on hover
    },
  }),
};
